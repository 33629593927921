@use '@carbon/themes/scss/tokens' as *;

@mixin from-desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin from-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

.footer-bar {
  display: flex;
  border-top: 1px solid $border-subtle-01;
  background-color: $background;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  color: black;

  .footer {
    padding-top: 15px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;

    .client-engineering {
      overflow-wrap: break-word;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 42px;
      line-height: 50px;
      color: #262626;

      .blue-ce {
        color: #0F62FE
      }
    }

    .explainer-ce {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #262626;
      overflow-wrap: break-word;
    }

    .call-to-action-ce {
      position: relative;
      height: 100%;
      min-height: 200px;
      display: grid;
      grid-column-gap: 30px;
      align-items: start;

      .text {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #262626;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        overflow-wrap: break-word;

        @media (max-width: 276px) {
          margin-bottom: 20px;
        }
        
      }
      
      .button {
        margin-top: auto;
        margin-bottom: auto;
        grid-column: 1 / 2;
        grid-row: 2 / 2;
        width: 100%;
        min-width: 100%;
      }
    }
  }
}

