@use '@carbon/themes/scss/tokens';
@use '@carbon/react/scss/spacing';
@use '@carbon/colors';

.grid-tile {
    background-color: #393939;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    gap: 16px;
    height: 100%;
    position: relative;
    overflow-wrap: break-word;
}

.cds--css-grid-column {
    padding-bottom: 16px;
    padding-top: 16px;
}

.tile-description {
    margin-bottom: 64px;
}

.grid-icon {
    height: 32px;
    width: 32px;
    margin-bottom: 16px;
}

.grid-tag-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
}

.grid-tag {
    margin: auto;
    margin-right: 4px;
    margin-top: 8px;
    border: none;
    font-style: normal;
}

.grid-url {
    bottom: 16px;
    left: 16px;
    margin-top: 16px;
    position: absolute;

    a:link {
        text-decoration: none;
    }
}