@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/react/scss/spacing';
@use '@carbon/colors';


.container {
    padding-bottom: 100px;
}

.button_login {
    margin-top: 8px;
    padding-right: 124px;

     @media (max-width: 276px) {
         min-width: 100%
        }
}

.error {
    font-family: 'IBMPlexSans';
	font-size: 14px;
	color: red;
}

#Login {
    color: $text-primary;
    text-decoration: none;
    transition: 0.3s all ease-in-out;
}

#Login:hover {
    color: $text-secondary;
}

@-moz-document url-prefix() {
	#login {
		font-family: 'IBM Plex Sans';
	}
}

.grid-area {
    font-family: 'IBM Plex Sans';
    background: #262626;

    background-image: url(../assets/img/justice_1_BG.svg);
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.willkommen {
    font-weight: 500;
    font-size: 54px;
    line-height: 64px;
    color: #FFFFFF;
    overflow-wrap: break-word;
}

@media only screen and (max-width: 460px) {
  .willkommen {
    font-size: 32px;
    line-height: 38px;
    margin-top: -80px;
  }
}

.header {
    margin-top: 80px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: spacing.$spacing-08;
}

.justice-icon-wrapper {

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .justice-icon {
        width: 172px;
        height: 166px;
        align-items: center;
    }

}

.entdecken {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 12px;
    overflow-wrap: break-word;
}

.searchbar {
    white-space: nowrap;
    display: inline;
    margin-top: 12px;
}

.tag {
    margin-top: -32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #FFFFFF;
}

.demos {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    margin-top: 50px;
    margin-bottom: 8px;
    color: #FFFFFF;
}

.kuerze {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    margin-top: 16px;
    margin-bottom: 24px;
    color: #FFFFFF;
}

.grid-tag {
    margin: auto;
    margin-right: 4px;
    margin-top: 8px;
    border: none;
    font-style: normal;
}

