@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme';
@use '@carbon/styles';
@use '@carbon/themes/scss/tokens';
@use '@carbon/react/scss/spacing';
@use '@carbon/grid';

html, body, #root{
  height: 100%;
  width: 100%;
  overflow: hidden;
}