@use "@carbon/themes/scss/tokens" as *;

.bodyWrapper{
    display: flex;
    height: 100%;
    padding-top: 3rem;
    flex-direction: column;
}

.componentSpace{
    flex-grow: 1;
    overflow-y: scroll;
}